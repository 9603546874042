import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Logo } from '../assets/img/svg/logo.svg'

function Footer() {
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='footer__inner'>
          <div className='footer__inner-logo'>
            <NavLink to='/'>
              <Logo />
            </NavLink>
          </div>
          <div className='footer__inner-items'>
            <div className='footer__inner-item'>
              <div className='footer__inner-item-title'>Navigation</div>
              <ul>
                <li>
                  <NavLink to='/'>Gift Cards</NavLink>
                </li>
                <li>
                  <NavLink to='/faq'>FAQs</NavLink>
                </li>
              </ul>
            </div>
            <div className='footer__inner-item'>
              <div className='footer__inner-item-title'>TENANTS</div>
              <ul>
                <li>
                  <NavLink to='/terms-conditions'>Terms & Conditions</NavLink>
                </li>
                <li>
                  <NavLink to='/privacy-policy'>Privacy Policy</NavLink>
                </li>
                <li>
                  <NavLink to='/disclaimer'>Disclaimer</NavLink>
                </li>
              </ul>
            </div>
            <div className='footer__inner-item'>
              <div className='footer__inner-item-title'>CONTACT US</div>
              <ul>
                <li>
                  <p>
                    15840228 Canada Inc. 2 Bloor St W, Toronto Suite 700 Toronto
                    ON M4W32
                  </p>
                </li>
                <li>
                  <a href='tel:14379629164'>+14379629164</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export { Footer }
